/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import Bowser from "bowser";
import { useDispatch, useSelector } from 'react-redux';
// import { Col, Row } from 'react-bootstrap'; //01829297069
// import moment from 'moment';
import { useLocation, Link, useHistory } from 'react-router-dom';
import Card from '../../Ui/Card';
import { getUserProfile } from '../../redux/features/user/userSlice';
import {
  createQRLoc,
  getQRLocById,
  removeFile,
  resetQR,
  setDescription,
  setEmail,
  setFile,
  setName,
  setPhone,
  // setQrCategory,
  // setQrCategory,
  setTitle,
} from '../../redux/features/qrCode/qrCodeSlice';
import icons from '../../utils/icon';
import './locationQrCode.css';
import doneLoge from '../../assets/img/done.jpg';

// import logo from '../../assets/img/FOX-Logo3.png';
// import { BASE_URL } from '../../utils/axios';
// import { getWorkOrderCategoryByAuthentication } from '../../redux/features/workOrderCategory/workOrderCategorySlice';

function LocationQRCode () {
  const [submited, setSubmited] = useState(false);
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector(state => state.user);
  const {
    locationService,
    title,
    name,
    email,
    phone,
    description,
    // category,
    attachment,
    isQRCreateSuccess,
    isQRGetError,
  } = useSelector(state => state.QRService);
  // const workOrderCategory = useSelector(state => state.workOrderCategory?.workOrderCategories);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const searchPramId = query.get('uuid');

  const uuid = searchPramId?.slice(0, searchPramId.length - 1);

  useEffect(() => {
    // dispatch(getWorkOrderCategoryByAuthentication());
    dispatch(getUserProfile());
    dispatch(getQRLocById(uuid));
  }, [dispatch]);

  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isQRGetError) {
      if (!searchPramId && user) {
        history.push('/');
      } else if (!searchPramId && !user) {
        history.push('/login');
      }
    }
  }, [searchPramId, user, isLoading, isQRGetError]);

  const handelTitle = e => {
    dispatch(setTitle(e.target.value));
  };
  const handelName = e => {
    dispatch(setName(e.target.value));
  };
  const handelEmail = e => {
    dispatch(setEmail(e.target.value));
  };
  const handelPhone = e => {
    dispatch(setPhone(e.target.value));
  };
  const handelDescription = e => {
    dispatch(setDescription(e.target.value));
  };
  const handelAttachment = e => {
    dispatch(setFile(e.target.files[0]));
  };

  const formdata = new FormData();
  formdata.append('title', title);
  formdata.append('name', name);
  formdata.append('email', email);
  formdata.append('phone', phone);
  // formdata.append('category', category);
  formdata.append('description', description);
  if (attachment) {
    formdata.append('attachment', attachment);
  }
  formdata.append('uuid', uuid);

  const handelSubmit = async e => {
    e.preventDefault();
    // if (title && name && phone && email && searchPramId) {
    await dispatch(createQRLoc(formdata));
    setSubmited(true);
    // }
  };
  let locationArray = locationService?.location?.split(',').filter(e => e.trim() !== '');


  const [browserName, setBrowserName] = useState('');


  useEffect(()=>{
    const getBrowserName = () => {
      const browser = Bowser.getParser(window.navigator.userAgent);
      setBrowserName( browser.getBrowserName());;
    };
    getBrowserName()
  })


  const pushToBrowserHomePage = () => {
    if (browserName === 'Microsoft Edge') {
       window.location.href = "https://ntp.msn.com/edge/ntp?locale=en-US&title=New%20tab&dsp=1&sp=Bing&startpage=1&PC=U531"
    }
    else{
      window.location.href = "https://www.google.com"
    }

  };



  return (
    <>
      {!submited ? (
        <section className="pt-3 sr-form" >
            {/* image title */}
            <section className="sr-form-title-wrapper">
                <div className="d-flex justify-content-center">
                  <img
                    // src ={logo}
                    src={locationService?.company_logo}
                    alt="company logo"
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <p
                  className='sr-form-title'
                  >
                    Facility Service Request
                  </p>
                </div>
              <div className="d-flex justify-center">
                <p
                className='sr-form-p'
                >
                  We are happy to know about your concerns!
                </p>
              </div>
            </section>
            <form onSubmit={handelSubmit}>
            <section
              style={{
                marginTop: '0',
              }}
            >
                <div>
                  <input
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 500,
                    }}
                    type="text"
                    value={`${locationService?.site} ${
                      locationService.location ? locationArray : ''
                    }`}
                  />
                </div>
                <div className="mt-3">
                  <input
                    required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 400,
                    }}
                    type="text"
                    placeholder="Feedback or Request Title"
                    onChange={handelTitle}
                  />
                </div>
                <div className="mt-3">
                  <input
                    required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 400,
                    }}
                    type="text"
                    placeholder="Name"
                    onChange={handelName}
                  />
                </div>
                <div className="mt-3">
                  <input
                  required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 400,
                    }}
                    onChange={handelEmail}
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className="mt-3">
                  <input
                  required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 400,
                    }}
                    onChange={handelPhone}
                    type="text"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="mt-3">
                  <textarea
                    style={{
                      // backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '130px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 400,
                    }}
                    type="text"
                    onChange={handelDescription}
                    placeholder={`Describe your Problem & Location\n \ne.g. The light is blinking at the level 1 lobby `}
                  />
                </div>
                <div className="file-input-container mt-3">
                  <input
                    onChange={handelAttachment}
                    type="file"
                    name="image"
                    id="image"
                    accept="image/png, image/jpeg"
                    className="file-input"
                  />
                </div>
                {attachment?.name && (
                  <div className="file-details-container mt-3">
                    <div className="file-display">
                      <span className="file-icon">📄</span>
                      <span className="file-name">{attachment.name}</span>
                      <span className="file-size">
                        {(attachment.size / (1024 * 1024)).toFixed(2)} MB
                      </span>
                      <button
                        onClick={() => dispatch(removeFile())}
                        type="button"
                        className="remove-button"
                      >
                        ✖
                      </button>
                    </div>
                  </div>
                )}

                <div className="mt-3">
                  <button
                  className='sr-form-submit'
                    type="submit"
                    style={{
                      height: '40px',
                      backgroundColor: '#404040',
                      borderRadius: '8px',
                      padding: '10px 16px 10px 16px',
                      color: '#FFFFFF',
                      fontFamily: 'Raleway',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}

                  >
                    Submit
                  </button>
                </div>
            </section>
            </form>

            <section className="d-flex justify-content-center">
              <div
                style={{
                  fontFamily: 'sans-serif',
                  fontWeight: 500,
                  lineHeight: '15px',
                  color: '#19191966',
                  marginTop: '30px',
                }}
              >
                <p style={{ fontSize: '16px', fontFamily: "sans-serif" }} className="text-center">
                  FaMES By TNBES IFM
                </p>
                <p style={{ fontSize: '14px' }} className="text-center">
                  Powered by FOX{' '}
                </p>
              </div>
            </section>
        </section>
      ) : (
        isQRCreateSuccess ?
        <section
          style={{
            backgroundColor: '#F7F7F7',
            height: '100vh',

          }}
          className=" d-flex justify-content-center align-items-center"
        >
          <div className='d-flex justify-content-center align-items-center' style={{flexDirection:"column"}}>
            <img src={doneLoge} alt="" />
            <p
              style={{
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: '18px',
                color: '#ADADAD',
                textAlign: 'center',
                marginTop: '15px',
              }}
            >
              Thank you <br /> Submitted Successfully
            </p>
            <button
              type="button"
              style={{
                backgroundColor: '#404040',
                borderRadius: '8px',
                padding: '10px 12px 10px 12px',
                height: '40px',
                width: '300px',
                color: '#FFFFFFFF',
                fontFamily: 'Raleway',
              }}
              onClick={pushToBrowserHomePage}
            >
              Done
            </button>
          </div>
        </section> : 
         <section
         style={{
           backgroundColor: '#F7F7F7',
           height: '100vh',
         }}
         className=" d-flex justify-content-center align-items-center"
       >
         <div className='d-flex justify-content-center align-items-center' style={{flexDirection:"column"}}>
         <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="300"
                                height="300"
                                fill="currentColor"
                                className="bi bi-x-circle-fill"
                                style={{ color: 'red', margin: 'auto' }}
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                              </svg>
           <p
             style={{
               fontFamily: 'Raleway',
               fontWeight: 600,
               fontSize: '18px',
               color: '#ADADAD',
               textAlign: 'center',
               marginTop: '15px',
             }}
           >
             Error! <br /> Something wrong with your Work Order <br /> Try again?{' '}
           </p>
           <button
             type="button"
             style={{
               backgroundColor: '#404040',
               borderRadius: '8px',
               padding: '10px 12px 10px 12px',
               height: '40px',
               width: '300px',
               color: '#FFFFFFFF',
               fontFamily: 'Raleway',
             }}
             onClick={() => {
                                     setSubmited(false);
                                     dispatch(getQRLocById(uuid));
                                     dispatch(resetQR());
                                   }}
                                   className='m-small-font'
                >
             Back
           </button>
         </div>
       </section>
      )}
    </>
  );
}

export default LocationQRCode;
