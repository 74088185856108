import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */

const selectSlice = createSlice({
  name: 'selectOPtions',
  initialState: {
    myId: null,
    openSite: false,
    valueOfSite: '',
    optionsSite: [],
    selectedSite: '',
    idOfSite: null,
    openLocation: false,
    valueOfLocation: '',
    optionsLocation: [],
    selectedLocation: '',
    idOfLocation: null,
    openCategory: false,
    valueOfCategory: '',
    optionsCategory: [],
    selectedCategory: '',
    idOfCategory: null,
    openStatus: false,
    valueOfStatus: '',
    optionsStatus: [],
    selectedStatus: '',
    idOfStatus: null,

    openAssetType: false,
    valueOfAssetType: '',
    optionsAssetType: [],
    selectedAssetType: '',
    idOfAssetType: null,

    openAssignedTo: false,
    valueOfAssignedTo: '',
    optionsAssignedTo: [],
    selectedAssignedTo: '',
    assignedTo: [],
    idsOfAssignedTo: [],
    openAsset: false,
    valueOfAsset: '',
    optionsAsset: [],
    assetInfo:null,
    selectedAsset: '',
    idOfAsset: null,
    assetDepartments: [],
  },
  reducers: {
    chooseSite (state, action) {
      state.selectedSite = action.payload.selected;
      state.idOfSite = action.payload.id;
      if (action.payload.selected !== null &&  action.payload.id !== null ) {
        state.openSite = false;
      }
      state.valueOfSite = '';
      state.selectedLocation = '';
    },
    searchOnSite (state, action) {
      state.valueOfSite = action.payload.valueOfInput;
      // state.optionsSite = action.payload.locations?.filter(opt =>
      //   opt.site.toLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase()),
      // );
    },
    openSiteSelect (state) {
      state.openSite = true;
    },
    closeSiteSelect (state) {
      state.openSite = false;
    },
    listItemsClickedSite (state) {
      state.openSite = !state.openSite;
    },
    chooseLocation (state, action) {
      state.selectedLocation = action.payload.selected;
      state.idOfLocation = action.payload.id;
      if (action.payload.selected !== null &&  action.payload.id !== null ) {
        state.openLocation = false;
      }
      state.valueOfLocation = '';
    },
    searchOnLocationWithSelect (state, action) {
      state.valueOfLocation = action.payload.valueOfInput;
      // state.optionsLocation = action.payload.subLocation?.filter(opt =>
      //   opt.room.toLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase()),
      // );
    },
    searchOnLocationWithoutSelect (state, action) {
      state.valueOfLocation = action.payload.valueOfInput;
      state.optionsLocation = action.payload.allSubLocations?.filter(opt =>
        opt.room.toLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase()),
      );
    },
    openLocationSelect (state) {
      state.openLocation = true;
    },
    closeLocationSelect (state) {
      state.openLocation = false;
    },
    listItemsClickedLocation (state) {
      state.openLocation = !state.openLocation;
    },
    chooseCategory (state, action) {
      state.selectedCategory = action.payload.selected;
      state.idOfCategory = action.payload.id;
      state.openCategory = false;
      state.valueOfCategory = '';
    },
    searchOnCategory (state, action) {
      state.valueOfCategory = action.payload.valueOfInput;
      state.optionsCategory = action.payload.assetCategories?.filter(opt =>
        opt.name.toLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase()),
      );
    },
    openCategorySelect (state) {
      state.openCategory = true;
    },
    closeCategorySelect (state) {
      state.openCategory = false;
    },
    listItemsClickedCategory (state) {
      state.openCategory = !state.openLocation;
    },
    chooseStatus (state, action) {
      state.selectedStatus = action.payload.selected;
      state.idOfStatus = action.payload.id;
      state.openStatus = false;
      state.valueOfStatus = '';
    },
    searchOnStatus (state, action) {
      state.valueOfStatus = action.payload.valueOfInput;
      state.optionsStatus = action.payload.assetStatuses?.filter(opt =>
        opt.name.toLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase()),
      );
    },
    openStatusSelect (state) {
      state.openStatus = true;
    },
    closeStatusSelect (state) {
      state.openStatus = false;
    },
    listItemsClickedStatus (state) {
      state.openStatus = !state.openLocation;
    },

    chooseAssetType (state, action) {
      state.selectedAssetType = action.payload.selected;
      state.idOfAssetType = action.payload.id;
      state.openAssetType = false;
      state.valueOfAssetType = '';
    },
    setAssetDepartment (state, action) {
      // state.assetDepartments.push(action.payload)
      state.assetDepartments = [action.payload];
    },
    searchOnAssetType (state, action) {
      state.valueOfAssetType = action.payload.valueOfInput;
      state.optionsAssetType = action.payload.assetTypes?.filter(opt =>
        opt.name.toLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase()),
      );
    },
    openAssetTypeSelect (state) {
      state.openAssetType = true;
    },
    closeAssetTypeSelect (state) {
      state.openAssetType = false;
    },
    listItemsClickedAssetType (state) {
      state.openAssetType = !state.openLocation;
    },

    chooseAssignedTo (state, action) {
      state.selectedAssignedTo = action.payload.selected;
      if (state.assignedTo.length > 0) {
        const index = state.assignedTo.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          const items = [...state.assignedTo];
          items.splice(index, 1);
          state.assignedTo = items;
        } else {
          state.assignedTo.push(action.payload);
        }
      } else {
        state.assignedTo.push(action.payload);
      }
      // state.openAssignedTo = false
      state.valueOfAssignedTo = '';
    },
    searchOnAssignedTo (state, action) {
      state.valueOfAssignedTo = action.payload.valueOfInput;
      state.optionsAssignedTo = action.payload.teams?.filter(opt =>
        opt.first_name.toLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase()),
      );
    },
    openAssignedToSelect (state) {
      state.openAssignedTo = true;
    },
    closeAssignedToSelect (state) {
      state.openAssignedTo = false;
    },
    listItemsClickedAssignedTo (state) {
      state.openAssignedTo = !state.openAssignedTo;
    },

    chooseAsset (state, action) {
      console.log("action", action);
      
      state.assetInfo = action.payload.opt
      state.selectedAsset = action.payload.selected;
      state.idOfAsset = action.payload.id;
      if (action.payload.selected !== null &&  action.payload.id !== null ) {
        state.openAsset = false;
      }
      state.valueOfAsset = '';
    },

    searchOnAsset (state, action) {
      state.valueOfAsset = action.payload.valueOfInput;

      const arr = [];
      action.payload.assetData?.map(opt => {
        if (
          opt.name.toLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase()) ||
          opt.asset_number.toLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase())
        ) {
          arr.push(opt);
        }
        opt.children.length > 0 &&
          opt.children.map(op => {
            if (
              op.name.toLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase()) ||
              op.asset_number
                .toLowerCase()
                .includes(action.payload.valueOfInput?.trim().toLowerCase())
            ) {
              arr.push(op);
            }
            op.children.length > 0 &&
              op.children.map(oc => {
                if (
                  oc.name
                    .toLowerCase()
                    .includes(action.payload.valueOfInput?.trim().toLowerCase()) ||
                  oc.asset_number
                    .toLowerCase()
                    .includes(action.payload.valueOfInput?.trim().toLowerCase())
                ) {
                  arr.push(oc);
                }
                oc.children.length > 0 &&
                  oc.children.map(ot => {
                    if (
                      ot.name
                        .toLowerCase()
                        .includes(action.payload.valueOfInput?.trim().toLowerCase()) ||
                      ot.asset_number
                        .toLowerCase()
                        .includes(action.payload.valueOfInput?.trim().toLowerCase())
                    ) {
                      arr.push(ot);
                    }
                    ot.children.length > 0 &&
                      ot.children.map(od => {
                        if (
                          od.name
                            .toLowerCase()
                            .includes(action.payload.valueOfInput?.trim().toLowerCase()) ||
                          od.asset_number
                            .toLowerCase()
                            .includes(action.payload.valueOfInput?.trim().toLowerCase())
                        ) {
                          arr.push(od);
                        }
                      });
                  });
              });
          });
      });

      state.optionsAsset = arr;
    },
    // opt.children.length > 0 && opt.children.filter(op => op.nametoLowerCase().includes(action.payload.valueOfInput?.trim().toLowerCase())
    // )

    openAssetSelect (state) {
      state.openAsset = true;
    },
    closeAssetSelect (state) {
      state.openAsset = false;
    },
    listItemsClickedAsset (state) {
      state.openAsset = !state.openLocation;
    },

    setIdOfSite (state, action) {
      state.idOfSite = action.payload;
    },
    setIdOfLocation (state, action) {
      state.idOfLocation = action.payload;
    },
    setIdOfCategory (state, action) {
      state.idOfCategory = action.payload;
    },
    setIdOfStatus (state, action) {
      state.idOfStatus = action.payload;
    },
    setIdOfAssetType (state, action) {
      state.idOfAssetType = action.payload;
    },
    setIdOfAssignedTo (state, action) {
      state.assignedTo = action.payload;
    },
    setIdOfAsset (state, action) {
      state.idOfAsset = action.payload;
    },

    reset (state) {
      state.openSite = false;
      state.valueOfSite = '';
      state.optionsSite = [];
      state.selectedSite = '';
      state.idOfSite = null;
      state.openLocation = false;
      state.valueOfLocation = '';
      state.optionsLocation = [];
      state.selectedLocation = '';
      state.idOfLocation = null;
      state.openCategory = false;
      state.valueOfCategory = '';
      state.optionsCategory = [];
      state.selectedCategory = '';
      state.idOfCategory = null;
      state.openStatus = false;
      state.valueOfStatus = '';
      state.optionsStatus = [];
      state.selectedStatus = '';
      state.idOfStatus = null;
      state.openAssetType = false;
      state.valueOfAssetType = '';
      state.optionsAssetType = [];
      state.selectedAssetType = '';
      state.idOfAssetType = null;
      state.openAssignedTo = false;
      state.valueOfAssignedTo = '';
      state.optionsAssignedTo = [];
      state.selectedAssignedTo = '';
      state.assignedTo = [];
      state.openAsset = false;
      state.valueOfAsset = '';
      state.optionsAsset = [];
      state.assetInfo = null;
      state.selectedAsset = '';
      state.idOfAsset = null;
    },
  },
});

export default selectSlice.reducer;
export const selectActions = selectSlice.actions;
