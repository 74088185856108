import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  closeEventModalCreate,
  createEvent,
  reset,
  resetEvent,
  setAssignedTo,
  setCategory,
  setDescription,
  setDueFinish,
  setPriority,
  // setTicketType,
  setTime,
  setTitle,
} from '../../redux/features/dashboard/dashboardSlice';
import {
  SelectAsset,
  SelectLocation,
  SelectPersons,
  SelectSite,
} from '../../components/select/select';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
// import { getDueDate } from '../../redux/features/workOrder/workOrderSlice';
import { getAssetsByRows } from '../../redux/features/asset/assetSlice';

/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable consistent-return */

export default function AddModalDashboard ({ handleClose, show, date, setDate }) {
  const dispatch = useDispatch();

  const assetListSearch = useSelector(state => state.asset?.assetListSearch);
  const workOrderCategory = useSelector(state => state.workOrderCategory?.workOrderCategories);
  const teams = useSelector(state => state.teams?.teams?.results);
  const { locations, sublocations } = useSelector(state => state.location);
  const { selectedSite, idOfSite, idOfLocation, idOfAsset,assetInfo } = useSelector(
    state => state.selectOptions,
  );

  /* const handelTicketType = e => {
    dispatch(setTicketType(e.target.id));
    dispatch(selectActions.reset());
  }; */

  const handelTitle = e => {
    dispatch(setTitle(e.target.value));
  };

  const handelCategory = e => {
    dispatch(setCategory(e.target.value));
  };

  const handelDescription = e => {
    dispatch(setDescription(e.target.value));
  };
  const handleDateTime = e => {
    dispatch(setTime(e.target.value));
    dispatch(setDueFinish(`${date} ${e.target.value}`));
  };

  const { isEventCreateSuccess, isEventCreateError, isEventCreateLoading } = useSelector(
    state => state.dashboard,
  );

  function displayToast () {
    if (isEventCreateError && !isEventCreateSuccess && !isEventCreateLoading) {
      toast.error('Something went wrong Creating Event', {
        toastId: 'CreatedEventError',
      });
      dispatch(reset());
    } else if (isEventCreateSuccess) {
      toast.success(' Event Created successfully', {
        toastId: 'Event Created Success',
      });
      dispatch(resetEvent());
      dispatch(closeEventModalCreate());
      dispatch(reset());
    }
  }
  const {
    ticketType,
    title,
    description,
    priority,
    category,
    due_finish,
    assigned_to,
    time,
    // fixedDate,
  } = useSelector(state => state.dashboard);

  const { dueDate } = useSelector(state => state.workOrder);

  const handelPriority = e => {
    dispatch(setPriority(e.target.value));
    // dispatch(getDueDate({ priority: e.target.value, date: fixedDate }));
  };
  useEffect(() => {
    if (dueDate) {
      setDate(moment.utc(dueDate?.due_date).format('YYYY-MM-DD'));
      dispatch(setTime(moment.utc(dueDate?.due_date).format('HH:mm')));
      dispatch(setDueFinish(moment.utc(dueDate?.due_date).format('YYYY-MM-DDTHH:mm')));
    }
  }, [dueDate]);

  useEffect(()=>{
    const getDataRows = async()=>{
      const filterData = {
        site: idOfSite || null,
        sublocation:idOfLocation || null,
        category : null
      }
      // if(idOfSite || idOfLocation){
       await dispatch(getAssetsByRows({ rowPerPage:10, currentPage:1, assetSearchValue:"", filterData }));
      // }
    }
    getDataRows()
    },[idOfSite,idOfLocation])

  const assign = assigned_to?.map(ele => ele?.value);
  let meta_type;
  if (idOfAsset) {
    meta_type = 'Asset';
  }
  if (idOfSite) {
    meta_type = 'Location';
  }
  if (ticketType === 'generalCheck') {
    meta_type = 'General';
  }
  const eventData = {
    title,
    description,
    priority: priority ? priority : null,
    category,
    due_finish: moment.utc(due_finish).format('YYYY-MM-DDTHH:mm:ss.SSZ'),
    assigned_to: assign,
    status: 'Pending',
    meta_type,
    asset: idOfAsset ? idOfAsset : null,
    location: idOfSite ? idOfSite : null,
    sub_location: idOfLocation ? idOfLocation : null,
  };

  const handelSave = async () => {
    if (
      title &&
      due_finish &&
      assigned_to.length > 0 &&
      category &&
      (idOfAsset || idOfSite || meta_type === 'General')
    ) {
      await dispatch(createEvent(eventData));
      await dispatch(selectActions.reset());
    } else {
      toast.warning('Please fill the required fields and submit again', {
        toastId: 'CreatedEventWarning',
      });
    }
  };

  const allSubLocations = [];

  return (
    <>
      {displayToast()}
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header>
          <Modal.Title>New Work Order</Modal.Title>
          <button className="btn secondary-btn" onClick={handleClose} type="button">
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* <label className="font-weight-normal col-12">
              Select Type: <span className="font-danger">*</span>
            </label>
            <label className="font-weight-normal col-12 form-control mb-2">
              <input
                type="radio"
                name="ticket_type"
                onClick={handelTicketType}
                id="assetCheck"
                required
              />{' '}
              Assets &nbsp; &nbsp;
              <input
                type="radio"
                name="ticket_type"
                onClick={handelTicketType}
                id="locationCheck"
              />{' '}
              Location &nbsp; &nbsp;
              <input
                type="radio"
                name="ticket_type"
                onClick={handelTicketType}
                id="generalCheck"
              />{' '}
              General
            </label> */}

            {/* {ticketType === 'locationCheck' && ( */}
              <div id="ifLocation" className="col-12 p-0">
                <SelectSite locations={locations} assetInfo={assetInfo} width="100%" noStar />
                {selectedSite && (
                  <div className="col-12 p-0" id="building_show">
                    <SelectLocation
                    assetInfo={assetInfo}
                      subLocation={sublocations?.results}
                      allSubLocations={allSubLocations}
                      width="100%"
                      noStar
                    />
                  </div>
                )}
              </div>
             {/* )} */}
            {/* {ticketType === 'assetCheck' && ( */}
              <div id="ifAsset" className="col-12 p-0">
                <label className="font-weight-normal col-12">Assets</label>
                <SelectAsset
                  width="100%"
                  setAssignedTo={setAssignedTo}
                  assetData={assetListSearch}
                />
              </div>
            {/* )} */}
            <input type="hidden" id="rm_id" value="{{data.room_id}}" />
            <input type="hidden" id="flr_id" value="{{data.floor_id}}" />
            <input type="hidden" id="build_id" value="{{data.building_id}}" />

            <label className="font-weight-normal col-12">
              Title <span className="font-danger">*</span>
            </label>
            <input
              required
              type="text"
              className="form-control mb-2"
              name="title2"
              id="title2"
              placeholder="Title"
              onChange={handelTitle}
            />

            <label className="font-weight-normal col-12">Priority</label>
            <select
              className="form-control select-dash custom-select2 mb-2"
              name="priority2"
              id="priority2"
              onChange={handelPriority}
            >
              <option value="" disabled selected>
                --SELECT--
              </option>
              {['High', 'Medium', 'Low'].map(val => (
                <option value={val}>{val}</option>
              ))}
            </select>

            <label className="font-weight-normal col-12">
              Category <span className="font-danger">*</span>
            </label>
            <select
              className="form-control select-dash custom-select2 mb-2"
              name="category"
              id="category2"
              onChange={handelCategory}
            >
              <option value="" disabled selected>
                --SELECT--
              </option>
              {workOrderCategory &&
                workOrderCategory.length > 0 &&
                workOrderCategory.map(cat => <option value={cat.name}>{cat.name}</option>)}
            </select>

            <label className="font-weight-normal col-12">
              Assigned to <span className="font-danger">*</span>
            </label>
            <div className="col-12 p-0">
              <SelectPersons teams={teams} assignedTo={assigned_to} setAssignedTo={setAssignedTo} />
            </div>
            <div className="col-12 row mb-2">
              <label className="font-weight-normal col-12">
                Due Date
                <span className="font-danger"> *</span>
              </label>
              <input
                required
                type="date"
                className="form-control col-6"
                name="due_finish2"
                id="due_finish2"
                value={date}
                readOnly
              />
              <input
                required
                type="time"
                className="form-control col-6"
                name="time_due"
                id="time_due"
                value={time}
                placeholder="Due Date"
                onChange={handleDateTime}
              />
            </div>
            <label className="font-weight-normal col-12">Description</label>
            <textarea
              className="form-control mb-2"
              name="description2"
              id="description2"
              placeholder="Description"
              onChange={handelDescription}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handelSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
