import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  // setEvents,
  getEventById,
  reset,
  setAssignedTo,
  setCategory,
  setCompletedDate,
  setDescription,
  setDueFinish,
  setEventId,
  setLocation,
  setPriority,
  setStartedDate,
  setStatus,
  setTime,
  setTitle,
  updateEvent,
  // setTicketType,
  setFixedDate,
  patchMeterEvent,
} from '../../redux/features/dashboard/dashboardSlice';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
// import EditPMModal from '../viewMaintenance/Componets/EditPMModal';
import {
  closeMaintenanceModalUpdate,
  getMaintenanceById,
  getPagination as getPaginationPM,
  openMaintenanceModalUpdate,
  setTab as pmTab,
  resetMaintenance,
  reset as resetPm,
} from '../../redux/features/maintenance/maintenanceSlice';
import {
  closeWorkOrderModalUpdate,
  getWorkOrderById,
  getPagination as getPaginationWO,
  openWorkOrderModalUpdate,
  setTab as woTab,
  reset as resetWO,
  resetWorkOrder,
  getDueDate,
} from '../../redux/features/workOrder/workOrderSlice';
// import EditWOModal from '../workOrder/Componets/EditWOModal';
import {
  SelectAsset,
  SelectLocation,
  SelectPersons,
  SelectSite,
} from '../../components/select/select';
import { getAssetById, getAssetsByRows } from '../../redux/features/asset/assetSlice';

/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */

export default function UpdateModalDashboard({ handleClose }) {
  const dispatch = useDispatch();
  const workOrderCategory = useSelector(state => state.workOrderCategory?.workOrderCategories);
  const pMCategory = useSelector(state => state.maintenanceCategory?.maintenanceCategories);
  const meterCategory = useSelector(state => state.meterCategory?.meterCategories);
  const teams = useSelector(state => state.teams?.teams?.results);
  const {
    event,
    eventModalUpdate,
    isEventGetSuccess,
    isEventUpdateSuccess,
    isEventUpdateError,
  } = useSelector(state => state.dashboard);
  const { idOfSite, idOfLocation, idOfAsset } = useSelector(
    state => state.selectOptions,
  );
  const { locations, sublocations } = useSelector(state => state.location);
  const { assetListSearch, asset } = useSelector(state => state.asset);
  const allSubLocations = [];

  useEffect(() => {
    if (isEventGetSuccess) {
      dispatch(selectActions.setIdOfSite(event?.location?.id));
      dispatch(selectActions.setIdOfLocation(event?.sub_location?.id));
      dispatch(selectActions.setIdOfAsset(event?.asset?.id));
      dispatch(
        setAssignedTo(
          event?.assigned_to?.length > 0
            ? event?.assigned_to?.map(i => ({
              value: i?.id,
              label: `${i?.name} (${i?.role === 'CliAdm' ? 'Admin' : i?.role})`,
              image: i?.image,
            }))
            : [],
        ),
      );
      dispatch(setCategory(event?.meter_number ? event?.category?.id : event?.category));
      dispatch(setDescription(event?.description));
      dispatch(setLocation(event?.location?.id));
      dispatch(setPriority(event?.priority));
      dispatch(setTime(event?.time));
      dispatch(setDueFinish(
        event?.service_end_date ?
          moment(event?.service_end_date).format('YYYY-MM-DDTHH:mm') :
          moment.utc(event?.due_finish).format('YYYY-MM-DDTHH:mm')));
      dispatch(setFixedDate(moment.utc(event?.due_finish).format('YYYY-MM-DD')));
      dispatch(setTitle(event?.title));
      dispatch(setStatus(event?.status));
      dispatch(
        setStartedDate(
          event?.start_date ? moment.utc(event?.start_date).format('YYYY-MM-DDTHH:mm') : null,
        ),
      );
      dispatch(
        setCompletedDate(
          event?.completed_date
            ? moment.utc(event?.completed_date).format('YYYY-MM-DDTHH:mm')
            : null,
        ),
      );
      dispatch(setEventId(event?.id));
    }
  }, [isEventGetSuccess]);

  const handelTitle = e => {
    dispatch(setTitle(e.target.value));
  };

  const handelCategory = e => {
    dispatch(setCategory(e.target.value));
  };

  const handelDescription = e => {
    dispatch(setDescription(e.target.value));
  };
  const handelStatus = e => {
    dispatch(setStatus(e.target.value));
  };
  const handleDateTime = e => {
    dispatch(setDueFinish(e.target.value));
  };
  const { dueDate } = useSelector(state => state.workOrder);

  useEffect(() => {
    if (dueDate) {
      dispatch(setDueFinish(moment.utc(dueDate?.due_date).format('YYYY-MM-DDTHH:mm')));
    }
  }, [dueDate]);

  const handleStartedDate = e => {
    dispatch(setStartedDate(e.target.value));
  };
  const handleCompletedDate = e => {
    dispatch(setCompletedDate(e.target.value));
  };

  const {
    ticketType,
    title,
    description,
    priority,
    category,
    due_finish,
    assigned_to,
    status,
    eventId,
    start_date,
    completed_date,
    fixedDate,
  } = useSelector(state => state.dashboard);
  const handelPriority = e => {
    dispatch(setPriority(e.target.value));
    if (!event?.meter_number) {
      dispatch(getDueDate({ priority: e.target.value, date: fixedDate }));
    }
  };
  const assign = assigned_to?.map(ele => ele?.value);
  let meta_type;
  if (idOfAsset) {
    meta_type = 'Asset';
  }
  if (idOfSite) {
    meta_type = 'Location';
  }
  if (event?.meta_type === 'General' || ticketType === 'generalCheck') {
    meta_type = 'General';
  }
  const eventData = {
    title,
    description,
    priority,
    category,
    due_finish: moment.utc(due_finish).format('YYYY-MM-DDTHH:mm:ss.SSZ'),
    assigned_to: assign,
    status,
    meta_type,
    asset: idOfAsset ? idOfAsset : null,
    location: idOfSite ? idOfSite : null,
    sub_location: idOfLocation ? idOfLocation : null,
    start_date: start_date ? moment.utc(moment(start_date)).format() : null,
    completed_date: completed_date ? moment.utc(moment(completed_date)).format() : null,
    id: eventId,
  };
  const eventMeterData = {
    title,
    description,
    priority,
    category,
    assigned_to: assign,
    status,
    meta_type,
    asset: idOfAsset ? idOfAsset : null,
    location: idOfSite ? idOfSite : null,
    sub_location: idOfLocation ? idOfLocation : null,
    id: eventId,
  };

  const {
    maintenanceModalUpdate,
    isMaintenanceDeleteSuccess,
    isMaintenanceUpdateSuccess,
    isMaintenanceDeleteError,
    isMaintenanceUpdateError,
  } = useSelector(state => state.maintenance);

  const {
    workOrderModalUpdate,
    isWorkOrderDeleteSuccess,
    isWorkOrderUpdateSuccess,
    isWorkOrderDeleteError,
    isWorkOrderUpdateError,
  } = useSelector(state => state.workOrder);

  function displayToast() {
    if (isEventUpdateError) {
      toast.error('Something went wrong Updating Event', {
        toastId: 'UpdatedEventError',
      });
      dispatch(reset());
    } else if (isEventUpdateSuccess) {
      toast.success('Event Updated successfully', {
        toastId: 'Event Updated Success',
      });
      dispatch(reset());
    } else if (isMaintenanceUpdateError) {
      toast.error('Something went wrong Updating Ticket', {
        toastId: 'UpdatingMaintenanceError',
      });
      dispatch(resetPm());
    } else if (isMaintenanceDeleteError) {
      toast.error('Something went wrong Deleting Ticket', {
        toastId: 'DeletingMaintenanceError',
      });
      dispatch(resetPm());
    } else if (isMaintenanceDeleteSuccess) {
      toast.success('Ticket Deleted successfully', {
        toastId: 'DeletedMaintenanceError',
      });
      dispatch(resetPm());
      dispatch(resetMaintenance());
      dispatch(closeMaintenanceModalUpdate());
    } else if (isMaintenanceUpdateSuccess) {
      toast.success('Ticket Updated successfully', {
        toastId: 'UpdatedMaintenanceError',
      });
    } else if (isWorkOrderUpdateError) {
      toast.error('Something went wrong Updating Ticket', {
        toastId: 'UpdatingWorkOrderError',
      });
      dispatch(resetWO());
    } else if (isWorkOrderDeleteError) {
      toast.error('Something went wrong Deleting Ticket', {
        toastId: 'DeletingWorkOrderError',
      });
      dispatch(resetWO());
    } else if (isWorkOrderDeleteSuccess) {
      toast.success('Ticket Deleted successfully', {
        toastId: 'DeletedWorkOrderError',
      });
      dispatch(resetWO());
      dispatch(resetWorkOrder());
      dispatch(closeWorkOrderModalUpdate());
    } else if (isWorkOrderUpdateSuccess) {
      toast.success('Ticket Updated successfully', {
        toastId: 'UpdatedWorkOrderError',
      });
    }
  }

  const [viewTicket, setViewTicket] = useState(false);
  useEffect(() => {
    const getEvent = async () => {
      if (isMaintenanceUpdateSuccess) {
        await dispatch(getEventById(event?.id));
        dispatch(closeMaintenanceModalUpdate());
        setViewTicket(false);
        dispatch(resetPm());
      } else if (isWorkOrderUpdateSuccess) {
        await dispatch(getEventById(event?.id));
        dispatch(closeWorkOrderModalUpdate());
        setViewTicket(false);
        dispatch(resetWO());
      }
    };
    getEvent();
  }, [isMaintenanceUpdateSuccess, isWorkOrderUpdateSuccess]);

  const handelSave = async () => {
    if (
      title &&
      due_finish &&
      assigned_to.length > 0 &&
      category &&
      (idOfAsset || idOfSite || meta_type === 'General')
    ) {
      if (event?.meter_number) {
        await dispatch(patchMeterEvent(eventMeterData));
        // dispatch(selectActions.reset());
        return;
      }
      if (status !== 'Completed' || status !== 'Started') {
        await dispatch(updateEvent(eventData));
        // dispatch(selectActions.reset());
      } else if (
        (status === 'Completed' && completed_date !== null) ||
        (status === 'Started' && start_date !== null)
      ) {
        await dispatch(updateEvent(eventData));
        // dispatch(selectActions.reset());
      }
    } else {
      toast.warning('Please fill the required fields and submit again', {
        toastId: 'CreatedEventWarning',
      });
    }
  };

  const getSelectedTickect = async id => {
    setViewTicket(true);
    const filterData ={ site: "" , sublocation: "" , filterCategory: "" , type: ""}
    if (event?.ticket_type === 'pm') {
      await dispatch(getMaintenanceById(id));
      dispatch(
        getPaginationPM({ rowPerPage: 10, currentPage: 1, searchValue: event?.title, filterData }),
      );
      dispatch(openMaintenanceModalUpdate());
      dispatch(pmTab('service-tab'));
    } else if (event?.ticket_type === 'workorder') {
      await dispatch(getWorkOrderById(id));
      dispatch(
        getPaginationWO({ rowPerPage: 10, currentPage: 1, searchValue: event?.title , filterData }),
      );
      dispatch(openWorkOrderModalUpdate());
      dispatch(woTab('service-tab'));
    }
  };
  useEffect(() => {
    if (!maintenanceModalUpdate) {
      setViewTicket(false);
    }
  }, [maintenanceModalUpdate]);

  useEffect(() => {
    if (!workOrderModalUpdate) {
      setViewTicket(false);
    }
  }, [workOrderModalUpdate]);
  useEffect(() => {
    const getDataRows = async () => {
      const filterData = {
        site: idOfSite || null,
        sublocation: idOfLocation || null,
        category: null
      }
      await dispatch(getAssetsByRows({ rowPerPage: 10, currentPage: 1, assetSearchValue: "", filterData }));
    }
    getDataRows()
  }, [idOfSite, idOfLocation])
  
  useEffect(() => {
    dispatch(getAssetById(event?.asset?.uuid))
  }, [event])
  const { user } = useSelector(state => state.user);
  const isTechCanEdit =
    user?.role === 'Technical Team' &&
    event?.service_request?.created_by?.role !== 'Technical Team';
  return (
    <>
      {displayToast()}
      {!viewTicket && (
        <Modal show={eventModalUpdate} onHide={handleClose} animation={true}>
          <Modal.Header>
            <Modal.Title>
              {event?.meter_number ? <h4 className="open-ticket">
                #{event?.meter_number}
              </h4> : <h4 className="open-ticket" onClick={() => getSelectedTickect(event?.id)}>
                #{event?.ticket_number}
              </h4>}
            </Modal.Title>
            <button className="btn secondary-btn" onClick={handleClose} type="button">
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div id="ifLocation" className="col-12 p-0">
                <SelectSite locations={locations} assetInfo={asset} width="100%" noStar />
                <div className="col-12 p-0" id="building_show">
                  <SelectLocation
                    assetInfo={asset}
                    subLocation={sublocations?.results}
                    allSubLocations={allSubLocations}
                    width="100%"
                    noStar
                  />
                </div>
              </div>
              <div id="ifAsset" className="col-12 p-0">
                <label className="font-weight-normal col-12">Assets</label>
                <SelectAsset
                  width="100%"
                  setAssignedTo={setAssignedTo}
                  assetData={assetListSearch}
                  woAsset={asset}
                />
              </div>
              <label className="font-weight-normal">
                Title <span className="font-danger">*</span>
              </label>
              <input
                required
                type="text"
                className="form-control mb-2"
                name="title2"
                id="title2"
                placeholder="Title"
                value={title}
                onChange={handelTitle}
              />
              <div className="col-12 row mb-2">
                <label className="font-weight-normal col-12">
                  {event?.meter_number ? "Start Date" : "Issued on"} 
                  </label>
                <input
                  required
                  type="datetime-local"
                  className="form-control"
                  name="due_finish2"
                  id="due_finish2"
                  readOnly
                  defaultValue={event && event?.service_start_date ?
                    moment(event?.service_start_date).format('YYYY-MM-DDTHH:mm') :
                    moment(event?.created_at).format('YYYY-MM-DDTHH:mm')}
                />
              </div>
              <div className="col-12 row mb-2">
                <label className="font-weight-normal col-12">
                  {event?.meter_number ? "End" : "Due"} Date
                  <span className="font-danger"> *</span>
                </label>
                <input
                  required
                  disabled={event?.meter_number}
                  type="datetime-local"
                  className="form-control"
                  name="due_finish2"
                  id="due_finish2"
                  value={due_finish}
                  onChange={handleDateTime}
                />
              </div>

              <label className="font-weight-normal">Priority</label>
              <select
                className="form-control select-dash custom-select2 mb-2"
                name="priority2"
                id="priority2"
                onChange={handelPriority}
                value={priority}
              >
                <option value="" disabled selected>
                  --SELECT--
                </option>
                {['High', 'Medium', 'Low'].map(val => (
                  <option value={val}>{val}</option>
                ))}
              </select>

              <label className="font-weight-normal">
                Category <span className="font-danger">*</span>
              </label>
              <select
                className="form-control select-dash custom-select2 mb-2"
                name="category"
                id="category2"
                onChange={handelCategory}
                value={category}
              >
                <option value="" disabled selected>
                  --SELECT--
                </option>
                {event?.ticket_type === 'pm'
                  ? pMCategory &&
                  pMCategory.length > 0 &&
                  pMCategory.map(cat => <option value={cat.name}>{cat.name}</option>)
                  : event?.ticket_type === 'workorder' ? workOrderCategory &&
                    workOrderCategory.length > 0 &&
                    workOrderCategory.map(cat => <option value={cat.name}>{cat.name}</option>)
                    : meterCategory &&
                    meterCategory.length > 0 &&
                    meterCategory.map(cat => <option value={cat.id}>{cat.name}</option>)}
              </select>
              <label className="font-weight-normal">
                Assigned to <span className="font-danger">*</span>
              </label>
              <div className="col-12 p-0">
                <SelectPersons
                  teams={teams}
                  assignedTo={assigned_to}
                  setAssignedTo={setAssignedTo}
                />
              </div>

              {!event?.meter_number && <>
                <label className="font-weight-normal">
                  Status <span className="font-danger">*</span>
                </label>
                <select
                  className="form-control select-dash custom-select2 mb-2"
                  name="status"
                  id="status"
                  onChange={handelStatus}
                  value={status}
                >
                  <option value="" disabled selected>
                    --SELECT--
                  </option>
                  {[
                    'Unassigned',
                    'Scheduled',
                    'Started',
                    'Completed',
                    'Delayed',
                    'Pending',
                    'Other',
                  ].map(val => (
                    <option value={val}>{val}</option>
                  ))}
                </select>
              </>
              }
              {status && status === 'Started' && (
                <div className="col-12 row mb-2">
                  <label className="font-weight-normal col-12">
                    Started Date
                    <span className="font-danger">*</span>
                  </label>
                  <input
                    required
                    type="datetime-local"
                    className="form-control"
                    name="due_finish2"
                    id="due_finish2"
                    value={start_date}
                    onChange={handleStartedDate}
                  />
                </div>
              )}
              {status && status === 'Completed' && (
                <div className="col-12 row mb-2">
                  <label className="font-weight-normal col-12">
                    Completed Date
                    <span className="font-danger">*</span>
                  </label>
                  <input
                    required
                    type="datetime-local"
                    className="form-control"
                    name="due_finish2"
                    id="due_finish2"
                    value={completed_date}
                    onChange={handleCompletedDate}
                  />
                </div>
              )}
              <label className="font-weight-normal">Description</label>
              <textarea
                className="form-control mb-2"
                name="description2"
                id="description2"
                placeholder="Description"
                onChange={handelDescription}
                value={description}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isTechCanEdit} variant="primary" onClick={handelSave}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* {workOrderModalUpdate &&<EditWOModal 
          openModalUpdate = {workOrderModalUpdate} 
          closeModalUpdate={() => dispatch(closeWorkOrderModalUpdate())}  />}
     {maintenanceModalUpdate && <EditPMModal 
          openModalUpdate = {maintenanceModalUpdate} 
          closeModalUpdate={() => dispatch(closeMaintenanceModalUpdate())} />} */}
    </>
  );
}
