/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlus , faMapMarked } from '@fortawesome/free-solid-svg-icons';
import {
  setLocation,
  openSiteModeul,
  closeSiteModeul,
  reset,
  exportLocation,
} from '../../redux/features/location/locationSlice';
import classes from '../../pages/assetsPage/assestsPage.module.css';
import IconDownload from '../../assets/svg-icons/IconDownload';

/* eslint-disable prefer-template */

export default function LocationHeader ({
  showSearchBar,
  setSearchBar,
  stylesHow,
  handlehow,
  data,
  showAdd,
  locations = [],
  myLocation,
  closeAdd,
  setSearchValue,
  locationSearcValue
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(myLocation || {});
  function handleLocation (id) {
    const location = locations.find(e => e?.id === id);
    setTitle(location);
    dispatch(setLocation(location));
    handlehow();
    closeAdd();
    dispatch(openSiteModeul());
  }
  const getFilteredItems = (query, lo) => {
    if (!query) {
      return lo;
    }
    return lo.filter(i => i.site.toLowerCase().includes(query.toLowerCase()));
  };
  const [query, setQuery] = useState('');
  const filteredItems = getFilteredItems(query, locations);
  const { profile } = useSelector(state => state.company);
  const  {locationsCount}  = useSelector(state => state.location);
  const limitOfLocations = profile?.subscription?.allowed_locations
  const isLocationLimitEnabled = profile?.subscription?.enable_locations_limit
 

const {fileExport} = useSelector(state => state.location);

  const fileExtension = '.csv';
  const fileExName = 'exported_location_data';


  const exportToCSV = async (apiData, fileName) => {
    const headers = Object.keys(apiData[0]);

    // Convert data to CSV format
    const csvContent = apiData.map(row => {
      return headers.map(header => {
        // If the field contains a comma or double quote, enclose it in quotes and escape any double quotes
        let field = row[header].replace(/"/g, '""'); // Escape double quotes
        if (field.includes(',') || field.includes('"') || field.includes('\n')) {
          field = `"${field}"`; // Enclose in quotes if necessary
        }
        return field;
      }).join(',');
    }).join('\n');

    // Construct the final CSV content
    const finalCSV = headers.join(',') + '\n' + csvContent;

    // Create a Blob from the CSV content with the appropriate Content-Type
    const fileData = new Blob([finalCSV], { type: 'text/csv' });

    // Save the Blob as a file
    FileSaver.saveAs(fileData, fileName + fileExtension);
  };


  useEffect(() => {
    if (fileExport?.length > 0) {
      exportToCSV(fileExport, fileExName);
      dispatch(reset());
    }
  }, [fileExport]);

  return (
    <div className={classes.assetsTop}>
      <h4 className={classes.assetsTitle}>Locations</h4>
      {
        showSearchBar && <div className={classes.assetActions}>
         {isLocationLimitEnabled && <div className="row info-users">
          <p className="my-auto">
            {locationsCount}/{limitOfLocations}
          </p>
          <FontAwesomeIcon icon={faMapMarked} />
        </div>}
        <input 
        onChange={(e)=>dispatch(setSearchValue(e.target.value))} 
        type="text" placeholder="Search" value={locationSearcValue} />
        {data?.role !== 'Technical Team' && (
          <button
            disabled
            onClick={() => {
              dispatch(setSearchBar(false))
              showAdd();
              // handlehow();
              dispatch(closeSiteModeul());
            }}
            type="button"
            className={classes.assetfaPlus}
          >
            <span>
              <FontAwesomeIcon icon={faPlus} />
            </span>
          </button>
        )}
        <IconButton title='Download' variant="contained"
              style={{
                backgroundColor: '#F8F9F9',
                borderRadius: '8px',
                border: '1px solid #E1E1E1'
              }}
              onClick={() => dispatch(exportLocation({ extension: 'JSON' }))} className='outlined-button'>
          <IconDownload />
        </IconButton>
      </div>
      }
    </div>
  );
}
