/* eslint-disable import/no-named-as-default */
import { configureStore } from '@reduxjs/toolkit';
import auth from './features/auth/authSlice';
import department from './features/department/departmentSlice';
import technicianPosition from './features/technicianPosition/technicianPositionSlice';
import inventoryCategory from './features/inventoryCategory/inventoryCategorySlice';
import checklists from './features/checklist/checklistSlice';
import assetCategory from './features/assetCategory/assetCategorySlice';
import assetStatus from './features/assetStatus/assetStatusSlice';
import maintenanceCategory from './features/maintenanceCategory/maintenanceCategorySlice';
import meterCategory from './features/meterCategory/meterCategorySlice';
import managerPosition from './features/managerPosition/managerPositionSlice';
import meterMeasurement from './features/meterMeasurement/meterMeasurementSlice';
import workOrderCategory from './features/workOrderCategory/workOrderCategorySlice';
import user from './features/user/userSlice';
import editCategory from './features/editCategory/editCategorySlice';
import teams from './features/teams/teamSlice';
import inventory from './features/inventory/inventorySlice';
import company from './features/compay/companySlice';
import location from './features/location/locationSlice';
import sublocation from './features/subLocation/subLocationSlice';
import selectOptions from './features/selectOptions/selectSlice';
import asset from './features/asset/assetSlice';
import dashboard from './features/dashboard/dashboardSlice';
import assetsMapping from './features/assetMapping/assetMappingSlice';
import meter from './features/meter/meterSlice';
import maintenance from './features/maintenance/maintenanceSlice';
import activity from './features/ticketActivity/ticketActivitySlice';
import logTime from './features/ticketLog/ticketLogSlice';
import workOrder from './features/workOrder/workOrderSlice';
import ticketChecklist from './features/ticketChecklist/ticketChecklistSlice';
import ticketinv from './features/ticketInventory/ticketInventorySlice';
import ticketComment from './features/ticketComment/ticketCommentSlice';
import QRService from './features/qrCode/qrCodeSlice';
import trigger from './features/workOrderTrigger/workOrderTriggerSlice';
import assetType from './features/assetType/assetTypeSlice';
import businessHour from './features/businessHour/businessHourSlice';
import SLA from './features/SLA/SLASlice';
import notification from './features/notification/notificationSlice';
import analytics from './features/Analytics/analyticsSlice';
import workOrderType from './features/workOrderType/workOrderTypeSlice';
import maintenanceType from './features/maintenanceType/maintenanceTypeSlice';
import assetLoanStatus from './features/assetLoanStatus/assetLoanStatusSlice'
import ticketNumberConfig from './features/referenceNumber/referenceNumberSlice'
import workOrderValidator from './features/workOrderValidator/workOrderValidatorSlice'


export default configureStore({
  reducer: {
    ticketNumberConfig,
    notification,
    SLA,
    businessHour,
    assetType,
    trigger,
    QRService,
    ticketinv,
    ticketComment,
    ticketChecklist,
    workOrder,
    asset,
    assetsMapping,
    activity,
    logTime,
    auth,
    dashboard,
    department,
    technicianPosition,
    inventoryCategory,
    assetCategory,
    assetStatus,
    maintenance,
    maintenanceCategory,
    meterCategory,
    meterMeasurement,
    meter,
    workOrderCategory,
    checklists,
    managerPosition,
    user,
    editCategory,
    teams,
    inventory,
    company,
    location,
    sublocation,
    selectOptions,
    analytics,
    workOrderType,
    maintenanceType,
    assetLoanStatus,
    workOrderValidator
  },
});
