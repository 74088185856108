import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getEventsByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/dashboard/`);

  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};
const getMeterEventsByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/meter-dashboard/`);

  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};


const getEventById = async (eventId) => {
  const res = await http.get(`${BASE_URL}/dashboard/${eventId}/`);

  return assert(res, res.data, 'Retrieval of event by its ID failed', res);
};

const createEvent = async (eventData) => {
  const res = await http.post(`${BASE_URL}/dashboard/`, eventData);

  return assert(res, res.data, 'Creation of event failed', res);
};

const deleteEvent = async (eventId) => {
  const res = await http.delete(`${BASE_URL}/dashboard/${eventId}`);
  return assert(res, eventId, 'Deletion of event failed', res);
};

const updateEvent = async (eventData) => {
  const res = await http.put(
    `${BASE_URL}/dashboard/${eventData.id}/`,
    eventData,
  );
  return assert(res, res.data, 'Update of event failed', res);
};

const EventService = {
  getEventsByAuthentication,
  getEventById,
  createEvent,
  deleteEvent,
  updateEvent,
  getMeterEventsByAuthentication
};

export default EventService;
