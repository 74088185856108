/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cropper from 'react-cropper';
import { useHistory } from 'react-router-dom';

// import dataUrlToFile from '../../utils/datatofile';
import LogoDef from '../../assets/img/FOX_Logo2.png.webp';
import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import {
  reset,
  getCompanyProfile,
  updateCompanyProfile,
  updateCompanyProfileImage,
} from '../../redux/features/compay/companySlice';
import './companyProfile.scss';

export default function CompanyProfile () {
  // const [logo, setLogo] = useState(LogoDef);
  const history = useHistory();
  const { profile, isLoading, isError, isSuccess } = useSelector(state => state.company);
  const [info, setInfo] = useState({
    name: '',
    address: '',
    phone_number: '',
    website: '',
    logo: profile?.logo || LogoDef,
  });
  const dispatch = useDispatch();
  const [phoneValid, setPhoneValid] = useState(true);
  const [urlValid, setUrlValid] = useState(true);
  const [crop, setCrop] = useState(false);
  const [image, setImage] = useState('');
  // const [imguplaod, setImaguplaod] = useState();
  const [cropData, setCropData] = useState('#');
  const cropperRef = useRef(null);
  const cropImage = e => {
    setCrop(true);
    const imgurl = URL.createObjectURL(e.target.files[0]);
    const form = new FormData();
    form.append('logo', e.target.files[0]);
    setImage(imgurl);

    e.preventDefault();
  };
  const getCropData = () => {
    if (cropperRef.current?.cropper !== 'undefined') {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      // setLogo(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());

      setInfo({ ...info, logo: cropperRef.current?.cropper.getCroppedCanvas().toDataURL() });
      cropperRef.current?.cropper.getCroppedCanvas().toBlob(blob => {
        const formData = new FormData();

        // Pass the image file name as the third parameter if necessary.
        formData.append('logo', blob, 'example.png');

        // Use `jQuery.ajax` method for example
        dispatch(updateCompanyProfileImage(formData));
      }, 'image/png');

      // form.append("logo", file);example.png
      setCrop(false);
    }
  };

  useEffect(() => {
    dispatch(getCompanyProfile());
  }, []);

  useEffect(() => {
    setInfo({
      name: profile?.name,
      address: profile?.address,
      phone_number: profile?.phone_number,
      website: profile?.website,
      logo: profile?.logo !== null && profile?.logo !== undefined ? profile?.logo : info.logo,
    });
  }, [profile]);

  const handleUpdateProfile = e => {
    e.preventDefault();
    const updateinfo = {
      name: info.name,
      address: info.address,
      phone_number: info.phone_number,
      website: info.website,
    };
    dispatch(updateCompanyProfile(updateinfo));
  };

  useEffect(() => {
    dispatch(reset());
  }, [profile, isError, isSuccess, isLoading, dispatch]);

  const { showSpinner } = useApiCallStatusNotificationHandler({
    isSuccess,
    isLoading,
    isError,
    successCallBack: () => setTimeout(() => {}, 3500),
  });
  const handlePhoneNumberChange = e => {
    const inputText = e.target.value;
    setInfo({ ...info, phone_number: inputText });
  };
  const testWebsiteLinkWithRegex = e => {
    setInfo({ ...info, website: e.target.value });
  };

  const { user } = useSelector((state) => state.user);
  
  const isAudit = user?.profile?.department_name === 'TNB ASSET AUDIT'

  return (
    <>
      {!crop && (
        <form
          action="{{action}}"
          method="POST"
          //   enctype="multipart/form-data"
          onSubmit={handleUpdateProfile}
        >
          {showSpinner ? <div id="loader" /> : null}

          <div className="card-header h-20 row justify-content-around col-lg-12">
            <div className="row justify-content-start my-auto col-lg-9 col-md-8 col-sm-12">
              <div className="icon img-hover ml-4 my-auto ">
                <div className="icon img-hover">
                  <div className="icon img-select">
                    <input
                      type="file"
                      id="company_profile_image"
                      accept="image/*"
                      name="profile_image"
                      className="none"
                      onChange={cropImage}
                      disabled={isAudit}
                    />
                  </div>
                  <label htmlFor="company_profile_image" className="image-label">
                    <img
                      className="prof_img img-company"
                      height="120"
                      width="120"
                      src={info.logo}
                      id="result_company_profile"
                      title="Change Company Logo"
                      alt="company default"
                      style={{ cursor: 'pointer' }}
                    />
                    <div className="edit-icon">
                      <div style={{fontSize:'18px'}} className="fas fa-pen"> </div>
                    </div>
                  </label>
                  <br />
                  <span id="image_name" className="gcolor small-font" htmlFor="crop_image">
                    {' '}
                  </span>
                  <input type="hidden" value="" name="crop" id="crop_image" />
                </div>
              </div>

              <div className="row pl-4  my-auto">
                <h3 className="col-lg-12">
                  <b>{info?.name}</b>
                </h3>
                {/* <div className="col-lg-12">
                  <label style={{ cursor: 'pointer' }} htmlFor="company_profile_image">
                    {' '}
                    Change company logo
                  </label>
                  <input
                    type="file"
                    id="company_profile_image"
                    accept="image/*"
                    name="profile_image"
                    className="none"
                    onChange="hi"
                  />
                </div> */}
              </div>
            </div>
            <div className="float-right my-auto col-lg-3 col-md-4 col-sm-12 d-flex justify-content-end">
              <button
                className="btn btn-primary "
                type="submit"
                id="profile_update"
                onClick={handleUpdateProfile}
                disabled={isAudit}
              >
                <span className="btn__text">Update</span>
              </button>
              <button
                onClick={() => history.push(isAudit ? "/assets" : "/")}
                className="btn secondary-btn ml-2"
                type="button"
              >
                Back
              </button>
            </div>
          </div>

          <div className="card-body mx-auto row justify-content-center col-lg-12 mt-10  mb-10">
            <div className="row col-lg-8 col-sm-12">
              <div className="card-container card col-lg-12 col-sm-12 ">
                <div className="card-body">
                  <div className="row">
                    <div className=" col-lg-6 col-sm-12">
                      <label className="font-weight-normal" htmlFor="company_name">
                        Company{' '}
                        <label className="font-danger m-0" htmlFor="company_name">
                          *
                        </label>
                      </label>
                      <input
                        type="text"
                        name="company"
                        id="company_name"
                        className="form-control mb-2"
                        required
                        placeholder="Company Name"
                        value={info.name !== null ? info.name : null}
                        onChange={e => setInfo({ ...info, name: e.target.value })}
                        disabled={isAudit}
                      />
                      <span className="font-danger col-lg-12 col-sm-12  ml-0" id="company_error">
                        {' '}
                      </span>
                    </div>
                    <br />
                    <div className=" col-lg-6 col-sm-12">
                      <label className="font-weight-normal" htmlFor="address">
                        Address
                      </label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        className="form-control mb-2"
                        placeholder="Company Address"
                        value={info.address !== null ? info.address : null}
                        onChange={e => setInfo({ ...info, address: e.target.value })}
                        disabled={isAudit}
                        // readOnly
                      />
                      <span className="font-danger col-lg-12 col-sm-12  ml-0" id="company_error">
                        {' '}
                      </span>
                    </div>

                    <br />
                    <div className=" col-lg-6 col-md-6 col-sm-12">
                      <label className="font-weight-normal" htmlFor="phoneNo">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        name="phoneNo"
                        id="phoneNo"
                        className="form-control mb-2"
                        placeholder="e.g. +601199119911"
                        value={info.phone_number !== null ? info.phone_number : null}
                        onChange={e => handlePhoneNumberChange(e)}
                        disabled={isAudit}
                        // readOnly
                      />
                      <span className="font-danger col-lg-12 col-sm-12  ml-0" id="company_error">
                        {/* {!phoneValid && 'Wrong phone number'} */}
                      </span>
                    </div>

                    <br />
                    <div className=" col-lg-6 col-md-6 col-sm-12">
                      <label className="font-weight-normal" htmlFor="website">
                        Website
                      </label>
                      <input
                        type="text"
                        name="website"
                        id="website"
                        className="form-control mb-2"
                        placeholder="e.g. example.com"
                        value={info.website !== null ? info.website : null}
                        onChange={e => testWebsiteLinkWithRegex(e)}
                        disabled={isAudit}
                        // readOnly
                      />
                      <span className="font-danger col-lg-12 col-sm-12  ml-0" id="company_error">
                        {/* {!urlValid && 'Not Valid URL'} */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </form>
      )}
      {crop ? (
        <div className="col croper">
          <Cropper
            style={{ height: '100%', width: '100%' }}
            initialAspectRatio={1}
            className="row align-items-center my-auto col-lg-12 col-md-12 col-sm-12 croper"
            preview=".img-preview"
            src={image}
            ref={cropperRef}
            viewMode={1}
            guides
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background
            responsive
            checkOrientation={false}
          />
          <div
            className="box"
            // style={{ width: "50%", float: "right", height: "300px" }}
          >
            <div className="buttons_crop">
              <button onClick={() => setCrop(false)} type="button" className="btn btn-danger">
                Cancel
              </button>

              <button onClick={getCropData} type="button" className="btn btn-success">
                Crop Image
              </button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
