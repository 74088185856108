/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable */

import React from 'react';
import { jsPDF } from "jspdf";
// import imageSample from '../../../assets/img/inventory_qr_code.png'
// import companyLogo from '../../../assets/img/download (1).png'

const printAssetQr = ({ asset }) => {
  const generatePDF = () => {
    const pdf = new jsPDF({
      orientation: 'landscape', // or 'landscape'
      unit: 'pt',
      format: [245, 175]// specify your custom page size in points
    });
    pdf.setFont("helvetica"); // Use 'helvetica' font
    pdf.setFontSize(12);

    // const companyLogoUrl = companyLogo;
    // const companyLogoUrl = `${company}?noChache=shimon969696`;
    // pdf.addImage(companyLogoUrl, 'PNG', 110,10,50,50);

    // const qrCodeDataUrl = imageSample;
    const qrCodeDataUrl = `${asset?.qr_code}?noChache=shimon9696`;
    pdf.addImage(qrCodeDataUrl, 'PNG', 20, 45, 80, 80)
    pdf.text(60, 35, '' + "Tenaga Nasional Berhad");

    pdf.setFontSize(9);
    // Handle long asset name
    pdf.text(105, 60, 'Asset name: ');
    const assetName = asset.name ? asset.name : '--';
    const wrappedName = pdf.splitTextToSize(assetName, 120); // Wrap text to fit within 100pt width
    pdf.text(105, 73, wrappedName);
    
    
    pdf.text(105, 105, 'Site: ');
    const site = asset.location ? asset.location.site : '--';
    const wrappedSite = pdf.splitTextToSize(site, 120); // Wrap text for the site
    pdf.text(105, 118, wrappedSite);

    pdf.setFontSize(12);
    // Define and center the asset number
    const assetNumber = asset.asset_number ? asset.asset_number : '--';
    const pageWidth = pdf.internal.pageSize.getWidth();
    const textWidth = pdf.getTextWidth(assetNumber);
    const xPosition = (pageWidth - textWidth) / 2;
    pdf.text(xPosition, 150, assetNumber);

    // Save the generated PDF and open in a new window
    const pdfBlob = pdf.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    window.open(pdfUrl, '_blank');
  };
  return (
    <span onClick={generatePDF}>QR</span>
  );
};

export default printAssetQr;