/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable operator-assignment */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import locationService from './locationService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Location data
  locations: [],
  location: {},
  searchValue: "",
  subLocationHistory: [],
  locationsCount: "0",

  fileExport: [],

  // sublocation data
  sublocations: [],
  sublocation: {},
  floor: {},
  searchonAssociatedLocation:{},
  floorPlans : [],
  floorPlansCount : "",
  searchValueOfPlan: "",
  pathValue: "",
  boolValue: false,
  locationSearcValue : "",

  // pagination
  currentPage: 1,
  rowPerPage: 10,

  // location fields
  locationSiteName: '',
  // floor model
  showFloorAdd: false,
  showAssociatedFloor: false,
  // show site
  showSiteModule: false,
  showSearchBar: true,
  // location modal
  showLocationModalDelete: false,
  showLocationModalUpdate: false,
  showLocationModalCreate: false,
  // location modal
  showSubLocationModalDelete: false,
  showSubLocationModalUpdate: false,
  showSubLocationModalCreate: false,

  // success location
  isLocationCreateSuccess: false,
  isLocationGetSuccess: false,
  isLocationDeleteSuccess: false,
  isLocationUpdateSuccess: false,
  // sucess sublocation
  isSubLocationCreateSuccess: false,
  isSubLocationGetSuccess: false,
  isSubLocationDeleteSuccess: false,
  isSubLocationUpdateSuccess: false,
  // success sub location
  isSubLocationHistoryGetSuccess: false,

  // loading location
  isLocationCreateLoading: false,
  isLocationDeleteLoading: false,
  isLocationUpdateLoading: false,
  isLocationGetLoading: false,
  isSublocationCreateLoading: false,
  // sub location history
  isSubLocationHistoryGetLoading: false,
  // loading sub location
  isSubLocationCreateLoading: false,
  isSubLocationDeleteLoading: false,
  isSubLocationUpdateLoading: false,
  isSubLocationGetLoading: false,

  // error location
  isLocationCreateError: false,
  isLocationGetError: false,
  isLocationDeleteError: false,
  isLocationUpdateError: false,

  // error Sub location
  isSubLocationCreateError: false,
  isSubLocationGetError: false,
  isSubLocationDeleteError: false,
  isSubLocationUpdateError: false,

  // sub location history error
  isSubLocationHistoryGetError: false,

  isLocationExported: false,
};

export const createLocation = createAsyncThunk('createLocation', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.createLocation, data, thunkAPI);
  return res;
});

export const createSubLocation = createAsyncThunk('createSubLocation', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.createSubLocation, data, thunkAPI);
  return res;
});

export const getLocationsByAuthentication = createAsyncThunk(
  'getLocationsByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(locationService.getLocationByAuthentication, data, thunkAPI);
    return res;
  },
);
export const getLocationByPagination = createAsyncThunk(
  'getLocationByPagination',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(locationService.getLocationByPagination, data, thunkAPI);
    return res;
  },
);

export const getLocationById = createAsyncThunk('getLocationById', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.getLocationById, data, thunkAPI);
  return res;
});

export const getSubLocationHistoryById = createAsyncThunk('getSubLocationHistoryById', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.getSubLocationHistoryById, data, thunkAPI);
  return res;
});

export const deleteLocation = createAsyncThunk('deleteLocation', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.deleteLocation, data, thunkAPI);
  return res;
});
export const deleteSubLocation = createAsyncThunk('deleteSubLocation', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.deleteSubLocation, data, thunkAPI);
  return res;
});

export const updateLocation = createAsyncThunk('updateLocation', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.updateLocation, data, thunkAPI);
  return res;
});
export const updateSubLocation = createAsyncThunk('updateSubLocation', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.updateSubLocation, data, thunkAPI);
  return res;
});

export const addFloor = createAsyncThunk('addFloor', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.createFloor, data, thunkAPI);
  return res;
})
export const getFloorList = createAsyncThunk('getFloorList', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.getFloorList, data, thunkAPI);
  return res;
})
export const getFloor = createAsyncThunk('getFloor', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.getFloor, data, thunkAPI);
  return res;
})
export const deleteFloor = createAsyncThunk('delteFloor', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.deleteFloor, data, thunkAPI);
  return res
})

export const updateFloor = createAsyncThunk('updateFloor', async (data, thunkAPI) => {
  const res = await smartTryCatch(locationService.updateFloor, data, thunkAPI)
  return res
})

export const getPagination = createAsyncThunk('getPaginationForSubLocation', async (data, thunkAPI) => {
  const getPM = await smartTryCatch(locationService.getPagination, data, thunkAPI);
  return getPM;
});
export const exportLocation = createAsyncThunk('exportLocation', async (data, thunkAPI) => {
  const exportLocationRes = await smartTryCatch(locationService.exportLocation, data, thunkAPI);
  return exportLocationRes;
});
// export const getPaginationFloorPlan = createAsyncThunk('getPaginationFloorPlan', async (data, thunkAPI) => {
//   const getPM = await smartTryCatch(locationService.getPaginationFloorPlan, data, thunkAPI);
//   return getPM;
// });

export const locationSlice = createSlice({
  name: 'location',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isLocationCreateError = false;
      state.isLocationCreateSuccess = false;
      state.isLocationCreateLoading = false;
      state.isLocationGetError = false;
      state.isLocationGetSuccess = false;
      state.isLocationGetLoading = false;
      state.isLocationDeleteError = false;
      state.isLocationDeleteSuccess = false;
      state.isLocationDeleteLoading = false;
      state.isLocationUpdateError = false;
      state.isLocationUpdateSuccess = false;
      state.isLocationUpdateLoading = false;
      state.location = null;
      state.showLocationModalDelete = false;
      state.showSubLocationModalDelete = false;
      state.showLocationModalUpdate = false;
      state.showLocationModalCreate = false;
      state.isSublocationCreateError = false;
      state.isSublocationCreateSuccess = false;
      state.isSublocationCreateLoading = false;
      state.isSublocationGetError = false;
      state.isSublocationGetSuccess = false;
      state.isSublocationGetLoading = false;
      state.isSublocationDeleteError = false;
      state.isSublocationDeleteSuccess = false;
      state.isSublocationDeleteLoading = false;
      state.isSublocationUpdateError = false;
      state.isSublocationUpdateSuccess = false;
      state.isSublocationUpdateLoading = false;
      state.isLocationExported = false;
      state.fileExport = [];

    },
    openLocationModalCreate: (state) => {
      state.showLocationModalCreate = true;
    },
    closeLocationModalCreate: (state) => {
      state.showLocationModalCreate = false;
    },
    openLocationModalUpdate: (state) => {
      state.showLocationModalUpdate = true;
    },
    closeLocationModalUpdate: (state) => {
      state.showLocationModalUpdate = false;
    },
    openLocationModalDelete: (state) => {
      state.showLocationModalDelete = true;
    },
    closeLocationModalDelete: (state) => {
      state.showLocationModalDelete = false;
    },
    openSubLocationModalCreate: (state) => {
      state.showSubLocationModalCreate = true;
    },
    closeSubLocationModalCreate: (state) => {
      state.showSubLocationModalCreate = false;
    },
    openSubLocationModalUpdate: (state) => {
      state.showSubLocationModalUpdate = true;
    },
    closeSubLocationModalUpdate: (state) => {
      state.showSubLocationModalUpdate = false;
    },
    openSubLocationModalDelete: (state) => {
      state.showSubLocationModalDelete = true;
    },
    closeSubLocationModalDelete: (state) => {
      state.showSubLocationModalDelete = false;
    },
    openAddFloor: (state) => {
      state.showFloorAdd = true;
    },
    closeAddFloor: (state) => {
      state.showFloorAdd = false;
    },
    openSiteModeul: (state) => {
      state.showSiteModule = true
    },
    closeSiteModeul: (state) => {
      state.showSiteModule = false
    },
    setSearchBar: (state , action) => {
      state.showSearchBar = action.payload
    },
    openAssociatedLocation: (state) => {
      state.showAssociatedFloor = true
      state.searchValue = ''
      state.searchonAssociatedLocation = {}
    },
    closeAssociatedLocation: (state) => {
      state.showAssociatedFloor = false
      state.searchValue = ''
      state.searchonAssociatedLocation = {}
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setLocationsReset: (state, action) => {
      state.locations = action.payload;
    },

    setsubLocation: (state, action) => {
      state.sublocation = action.payload;
    },
    setAssociatedLocation: (state, action) => {
      state.floor = action.payload
    },
    setSearchValue : (state, action) => {
      state.searchValue = action.payload;
    },
    setLocationSearcValue : (state, action) => {
      state.locationSearcValue = action.payload;
    },
    setSearchValueOfPlan : (state, action) => {
      state.searchValueOfPlan = action.payload;
    },
    setPathValue : (state, action) => {
      state.pathValue = action.payload;
    },
    setBoolValue : (state, action) => {
      state.boolValue = action.payload;
    },
    setFloor : (state , action) => {
      const arr = [] 
      state.floor?.associated_location?.map(opt => { 
        if(opt.room.toLowerCase().includes(action.payload.toLowerCase())){
          arr.push(opt)
      }})

      state.floor?.dissociated_location?.map(opt => { 
        if(opt.room.toLowerCase().includes(action.payload.toLowerCase())){
           arr.push(opt)
      }})
    state.searchonAssociatedLocation = arr
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLocation.pending, (state) => {
        state.isLocationCreateLoading = true;
        state.isLocationCreateSuccess = false;
        state.isLocationCreateError = false;
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        state.isLocationCreateLoading = false;
        state.isLocationCreateSuccess = true;
        state.isLocationCreateError = false;
        state.locations.push(action.payload);
        state.location = action.payload
        state.locationsCount = state.locationsCount + 1
      })
      .addCase(createLocation.rejected, (state) => {
        state.isLocationCreateLoading = false;
        state.isLocationCreateSuccess = false;
        state.isLocationCreateError = true;
      })
      .addCase(createSubLocation.pending, (state) => {
        state.isLocationCreateLoading = true;
        state.isLocationCreateSuccess = false;
        state.isLocationCreateError = false;
      })
      .addCase(createSubLocation.fulfilled, (state, action) => {
        state.isLocationCreateLoading = false;
        state.isLocationCreateSuccess = true;
        state.isLocationCreateError = false;
        state.sublocations.results = [action.payload , ...state.sublocations.results];
      })
      .addCase(createSubLocation.rejected, (state) => {
        state.isLocationCreateLoading = false;
        state.isLocationCreateSuccess = false;
        state.isLocationCreateError = true;
      })
      .addCase(getLocationsByAuthentication.pending, (state) => {
        state.isLocationGetLoading = true;
        state.isLocationGetSuccess = false;
        state.isLocationGetError = false;
      })
      .addCase(getLocationsByAuthentication.fulfilled, (state, action) => {
        state.isLocationGetLoading = false;
        state.isLocationGetSuccess = true;
        state.isLocationGetError = false;
        state.locations = action.payload?.results;
      })
      .addCase(getLocationsByAuthentication.rejected, (state) => {
        state.isLocationGetLoading = false;
        state.isLocationGetSuccess = false;
        state.isLocationGetError = true;
      })
      .addCase(getLocationByPagination.pending, (state) => {
        state.isLocationGetLoading = true;
        state.isLocationGetSuccess = false;
        state.isLocationGetError = false;
      })
      .addCase(getLocationByPagination.fulfilled, (state, action) => {
        state.isLocationGetLoading = false;
        state.isLocationGetSuccess = true;
        state.isLocationGetError = false;
        state.locations = action.payload?.results;
        state.locationsCount = action.payload?.count;
      })
      .addCase(getLocationByPagination.rejected, (state) => {
        state.isLocationGetLoading = false;
        state.isLocationGetSuccess = false;
        state.isLocationGetError = true;
      })
      .addCase(getLocationById.pending, (state) => {
        state.isLocationGetLoading = true;
        state.isLocationGetSuccess = false;
        state.isLocationGetError = false;
      })
      .addCase(getLocationById.fulfilled, (state, action) => {
        state.isLocationGetLoading = false;
        state.isLocationGetSuccess = true;
        state.isLocationGetError = false;
        state.location = action.payload;
      })
      .addCase(getLocationById.rejected, (state) => {
        state.isLocationGetLoading = false;
        state.isLocationGetSuccess = false;
        state.isLocationGetError = true;
      })
      .addCase(getSubLocationHistoryById.pending, (state) => {
        state.isLocationGetLoading = false;
        state.isLocationGetSuccess = false;
        state.isLocationGetError = true;
      })
      .addCase(getSubLocationHistoryById.fulfilled, (state, action) => {
        state.isLocationGetLoading = false;
        state.isLocationGetSuccess = true;
        state.isLocationGetError = false;
        state.subLocationHistory = action.payload;
      })
      .addCase(getSubLocationHistoryById.rejected, (state) => {
        state.isLocationGetLoading = false;
        state.isLocationGetSuccess = false;
        state.isLocationGetError = true;
      })
      .addCase(deleteLocation.pending, (state) => {
        state.isLocationDeleteLoading = true;
        state.isLocationDeleteSuccess = false;
        state.isLocationDeleteError = false;
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        state.isLocationDeleteLoading = false;
        state.isLocationDeleteSuccess = true;
        state.isLocationDeleteError = false;
        state.locations = state.locations.filter((location) => location.id !== action.payload);
        state.locationsCount = state.locationsCount - 1
        state.location = {}
      })
      .addCase(deleteLocation.rejected, (state) => {
        state.isLocationDeleteLoading = false;
        state.isLocationDeleteSuccess = false;
        state.isLocationDeleteError = true;
      })
      .addCase(deleteSubLocation.pending, (state) => {
        state.isLocationDeleteLoading = true;
        state.isLocationDeleteSuccess = false;
        state.isLocationDeleteError = false;
      })
      .addCase(deleteSubLocation.fulfilled, (state, action) => {
        state.isLocationDeleteLoading = false;
        state.isLocationDeleteSuccess = true;
        state.isLocationDeleteError = false;
        state.sublocations.results = state.sublocations?.results?.filter(
          (location) => location.id !== action.payload,
        );
      })
      .addCase(deleteSubLocation.rejected, (state) => {
        state.isLocationDeleteLoading = false;
        state.isLocationDeleteSuccess = false;
        state.isLocationDeleteError = true;
      })
      .addCase(updateLocation.pending, (state) => {
        state.isLocationUpdateLoading = true;
        state.isLocationUpdateSuccess = false;
        state.isLocationUpdateError = false;
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        state.isLocationUpdateLoading = false;
        state.isLocationUpdateSuccess = true;
        state.isLocationUpdateError = false;
        state.locations = state.locations.map((location) =>
          location.id === action.payload.id ? action.payload : location,
        );
        state.location = action.payload;
      })
      .addCase(updateLocation.rejected, (state) => {
        state.isLocationUpdateLoading = false;
        state.isLocationUpdateSuccess = false;
        state.isLocationUpdateError = true;
      })
      .addCase(updateSubLocation.pending, (state) => {
        state.isLocationUpdateLoading = true;
        state.isLocationUpdateSuccess = false;
        state.isLocationUpdateError = false;
      })
      .addCase(updateSubLocation.fulfilled, (state, action) => {
        state.isLocationUpdateLoading = false;
        state.isLocationUpdateSuccess = true;
        state.isLocationUpdateError = false;
        state.sublocations.results = state.sublocations?.results?.map((location) =>
          location.id === action.payload.id ? action.payload : location,
        );
      })
      .addCase(updateSubLocation.rejected, (state) => {
        state.isLocationUpdateLoading = false;
        state.isLocationUpdateSuccess = false;
        state.isLocationUpdateError = true;
      })
      .addCase(addFloor.pending, (state) => {
        state.isLocationCreateLoading = true;
        state.isLocationCreateSuccess = false;
        state.isLocationCreateError = false;
      })
      .addCase(addFloor.fulfilled, (state, action) => {
        state.isLocationCreateLoading = false;
        state.isLocationCreateSuccess = true;
        state.isLocationCreateError = false;
        state.floorPlans.push(action.payload);
      })
      .addCase(addFloor.rejected, (state) => {
        state.isLocationCreateLoading = false;
        state.isLocationCreateSuccess = false;
        state.isLocationCreateError = true;
      })
      .addCase(getFloorList.pending, (state) => {
        state.isLocationCreateLoading = true;
        state.isLocationCreateSuccess = false;
        state.isLocationCreateError = false;
      })
      .addCase(getFloorList.fulfilled, (state, action) => {
        state.isLocationCreateLoading = false;
        state.isLocationCreateSuccess = true;
        state.isLocationCreateError = false;
        state.floorPlans = action.payload?.results;
        state.floorPlansCount = action.payload?.count;
      })
      .addCase(getFloorList.rejected, (state) => {
        state.isLocationCreateLoading = false;
        state.isLocationCreateSuccess = false;
        state.isLocationCreateError = true;
      })
      .addCase(getFloor.pending, (state) => {
        state.isLocationCreateLoading = true;
        state.isLocationCreateSuccess = false;
        state.isLocationCreateError = false;
      })
      .addCase(getFloor.fulfilled, (state, action) => {
        state.isLocationCreateLoading = false;
        state.isLocationCreateSuccess = true;
        state.isLocationCreateError = false;
        state.floor = action.payload;
      })
      .addCase(getFloor.rejected, (state) => {
        state.isLocationCreateLoading = false;
        state.isLocationCreateSuccess = false;
        state.isLocationCreateError = true;
      })
      .addCase(deleteFloor.pending, (state) => {
        state.isLocationDeleteLoading = true;
        state.isLocationDeleteSuccess = false;
        state.isLocationDeleteError = false;
      })
      .addCase(deleteFloor.fulfilled, (state, action) => {
        state.isLocationDeleteLoading = false;
        state.isLocationDeleteSuccess = true;
        state.isLocationDeleteError = false;
        state.floorPlans = state.floorPlans?.filter(
          (location) => location.id !== action.payload,
        );
      })
      .addCase(deleteFloor.rejected, (state) => {
        state.isLocationDeleteLoading = false;
        state.isLocationDeleteSuccess = false;
        state.isLocationDeleteError = true;
      }).addCase(updateFloor.pending, (state) => {
        state.isLocationUpdateLoading = true;
        state.isLocationUpdateSuccess = false;
        state.isLocationUpdateError = false;
      })
      .addCase(updateFloor.fulfilled, (state, action) => {
        state.isLocationUpdateLoading = false;
        state.isLocationUpdateSuccess = true;
        state.isLocationUpdateError = false;
        state.floorPlans = state.floorPlans.map((floor) =>
          floor.id === action.payload.id ? action.payload : floor)
      })
      .addCase(updateFloor.rejected, (state) => {
        state.isLocationUpdateLoading = false;
        state.isLocationUpdateSuccess = false;
        state.isLocationUpdateError = true;
      })  .addCase(getPagination.fulfilled , (state , action)=>{
        state.sublocations = action.payload
    }).addCase(exportLocation.pending, state => {
      state.isLocationExported = false;
    })
    .addCase(exportLocation.fulfilled, (state, action) => {
      state.isLocationExported = true;
      state.fileExport = action.payload;
    })
    .addCase(exportLocation.rejected, state => {
      state.isLocationExported = false;
    })
  },
});

export const {
  reset,
  openLocationModalCreate,
  closeLocationModalCreate,
  openLocationModalUpdate,
  closeLocationModalUpdate,
  openLocationModalDelete,
  closeLocationModalDelete,
  openSubLocationModalCreate,
  closeSubLocationModalCreate,
  openSubLocationModalUpdate,
  closeSubLocationModalUpdate,
  openSubLocationModalDelete,
  closeSubLocationModalDelete,
  setLocation,
  setsubLocation,
  setLocationsReset,
  openAddFloor,
  closeAddFloor,
  openSiteModeul,
  closeSiteModeul,
  setSearchBar,
  closeAssociatedLocation,
  openAssociatedLocation,
  setAssociatedLocation,
  setSearchValue,
  setSearchValueOfPlan,
  setPathValue,
  setBoolValue,
  setFloor,
  setCurrentPage,
  setRowPerPage,
  setLocationSearcValue 
} = locationSlice.actions;

export default locationSlice.reducer;
