/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import Calendar from '../../components/calendar/calendar';
import {
  closeEventModalCreate, closeEventModalUpdate,
  // getEventById,
  getEventsByAuthentication,
  // openEventModalUpdate,
  resetEvent, setEvent, setEvents,
  getMeterEventsByAuthentication
} from '../../redux/features/dashboard/dashboardSlice';
import { getAssetListSearch, setAsset } from '../../redux/features/asset/assetSlice';
import { getWorkOrderCategoryByAuthentication } from '../../redux/features/workOrderCategory/workOrderCategorySlice';
import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
import { getMaintenanceCategoryByAuthentication } from '../../redux/features/maintenanceCategory/maintenanceCategorySlice';
import { getLocationsByAuthentication } from '../../redux/features/location/locationSlice';
import AddModalDashboard from './addModal';
import "./dashboard.scss"
import UpdateModalDashboard from './updateModal';
import Loader from '../../components/loader/loader';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import { getMeterCategoryByAuthentication } from '../../redux/features/meterCategory/meterCategorySlice';
import {
  // closeMaintenanceModalUpdate,
  getPagination as getPaginationPM,
  openMaintenanceModalUpdate,
  setTab as pmTab,
  // resetMaintenance,
  // reset as resetPm,
} from '../../redux/features/maintenance/maintenanceSlice';
import {
  getMaintenanceById,
  // closeWorkOrderModalUpdate,
  getWorkOrderById,
  getPagination as getPaginationWO,
  openWorkOrderModalUpdate,
  // setTab as woTab,
  // reset as resetWO,
  // resetWorkOrder,
  // getDueDate,
  setDueDate,
  setOpenFrom,
  setWorkOrder,
  resetWorkOrder
} from '../../redux/features/workOrder/workOrderSlice';
import {
  getMeterById, openMeterModalUpdate, getMetersByPagination,
  setTab as meterTab
} from '../../redux/features/meter/meterSlice';


export default function Dashboard() {
  const dispatch = useDispatch()
  const { events, eventModalCreate, isEventCreateLoading, isEventUpdateLoading, eventModalUpdate } = useSelector(state => state.dashboard)
  const { user } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getEventsByAuthentication());
      await dispatch(getMeterEventsByAuthentication());
      dispatch(getWorkOrderCategoryByAuthentication());
      dispatch(getMaintenanceCategoryByAuthentication());
      dispatch(getMeterCategoryByAuthentication());
      dispatch(getTeamsByAuthentication());
      dispatch(getLocationsByAuthentication());
      dispatch(getAssetListSearch());
      setIsLoading(false)
    }
    fetchData();
  }, [])

  const handleClose = () => {
    dispatch(closeEventModalCreate());
    dispatch(resetEvent())
  }
  const handleUpdateClose = () => dispatch(closeEventModalUpdate());
  const [date, setDate] = useState("")

  // const handleDateClick = (arg) => {
  //   dispatch(setEvent(null))
  //   dispatch(setAsset(null))
  //   dispatch(resetEvent())
  //   dispatch(dispatch(selectActions.reset()))
  //   dispatch(openEventModalCreate())
  //   setDate(arg.dateStr)
  //   dispatch(setDueDate(""))
  //   dispatch(setFixedDate(arg.dateStr))
  //   dispatch(setDueFinish(arg.dateStr))
  // };

  const getSelectedTickect = async (id, ticketType, ticketTitle) => {
    const filterData = {
      site: "", sublocation: "", filterCategory: "", type: "",
      staff: "", status: "", frequency: "", prioirty: "", startDate: "", endDate: "", pmSchedule: "",
      asset: "",
    }
    if (ticketType === 'pm') {
      dispatch(setWorkOrder({}))
      dispatch(resetWorkOrder())
      dispatch(setAsset(null))
      dispatch(dispatch(selectActions.reset()))
      await dispatch(getMaintenanceById(id));
      await dispatch(
        getPaginationPM({ rowPerPage: 10, currentPage: 1, searchValue: ticketTitle, ...filterData }),
      );
      dispatch(openWorkOrderModalUpdate());
      dispatch(setOpenFrom("Maintenance"));
    } else if (ticketType === 'workorder') {
      dispatch(setWorkOrder({}))
      dispatch(resetWorkOrder())
      dispatch(setAsset(null))
      dispatch(dispatch(selectActions.reset()))
      await dispatch(getWorkOrderById(id));
      dispatch(
        getPaginationWO({ rowPerPage: 10, currentPage: 1, searchValue: ticketTitle, ...filterData }),
      );
      dispatch(openWorkOrderModalUpdate());
      // dispatch(woTab('service-tab'));
    } else if (ticketType === 'meter') {
      await dispatch(getMeterById(id));
      dispatch(
        getMetersByPagination({ rowPerPage: 10, currentPage: 1, SearchVal: ticketTitle }),
      );
      dispatch(openMeterModalUpdate());
      dispatch(meterTab('service-tab'));
    }
  };

  const handleEventClick = async (arg) => {
    dispatch(setEvent(null))
    dispatch(setAsset(null))
    dispatch(resetEvent())
    setDate("")
    dispatch(setDueDate(""))
    dispatch(selectActions.reset())
    getSelectedTickect(arg.event.extendedProps.eventId, arg.event.extendedProps.ticket_type, arg.event.extendedProps.name)
  }


  const getEventColor = (status) => {
    switch (status) {
      case "Open":
        return "#6591AC";
      case "In-Progress":
        return "#FF8633";
      case "On-Hold":
        return "#C23B22";
      case "Completed":
        return "#4EA72E";
      default:
        return "black";
    }
  };
  // Modify your eventStyle function to ensure unique IDs
  const eventStyle = (ev) => {
    return {
      ...ev,
      id: `${ev.id}${ev.ticket_type}`, // Append index to ensure uniqueness
      eventId: ev.id,
      title: `${moment.utc((ev.due_finish ? ev?.due_finish : ev?.reading_date)).format('h:mm a')} - ${ev.title}`,
      date: moment.utc(new Date(ev.due_finish ? ev?.due_finish : ev?.reading_date)).format("YYYY-MM-DD"),
      backgroundColor: getEventColor(ev?.status || ""),
      borderColor: "white",
      borderRadius: "10px",
      name: ev.title
    };
  };


  const [eventsData, setEventsData] = useState([])

  useEffect(() => {
    setEventsData(events && events.length > 0 && events.map((ev, index) => eventStyle(ev, index)))
  }, [events])

  const {
    meter
  } = useSelector(state => state.meter);
  const {
    maintenance,
  } = useSelector(state => state.maintenance);
  const {
    workOrder,
  } = useSelector(state => state.workOrder);


  useEffect(() => {
    if (meter?.id) {
      dispatch(setEvents(events?.map(item => item.id === meter?.id && item?.ticket_type === "meter" ? meter : item)))
    }
  }, [meter])

  useEffect(() => {
    if (maintenance?.id) {
      dispatch(setEvents(events?.map(item => item.id === maintenance?.id && item?.ticket_type === "pm" ? maintenance : item)))
    }
  }, [maintenance])

  useEffect(() => {
    if (workOrder?.id) {
      dispatch(setEvents(events?.map(item => item.id === workOrder?.id && (item?.ticket_type === "workorder" ||item?.ticket_type === "pm" ) ? workOrder : item)))
    }
  }, [workOrder])
  

  return (
    isLoading ? <Loader /> :
      <div className='calender-wraper'>
        <h4 className='dash-title'><span>Welcome, </span>{user && user.first_name}</h4>
        {(isEventCreateLoading || isEventUpdateLoading) ? <Loader /> :
          <div className='calender'>
            <Calendar events={eventsData || []}
              // handleDateClick={handleDateClick}
              handleEventClick={handleEventClick} />
          </div>}
        {eventModalCreate && <AddModalDashboard date={date} setDate={setDate} show={eventModalCreate}
          handleClose={handleClose} />}
        {eventModalUpdate && <UpdateModalDashboard handleClose={handleUpdateClose} />}
      </div>
  );
}
